























































































































































































































import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import { Component, Vue } from 'vue-property-decorator';
import store from '@/services/store';
import CBudgetSumCalcProgTable from '@/modules/budget-request/components/budget-sum-calc/budget-sum-calc-prog-table.vue';
import CBudgetSumCalcProgGuTable from '@/modules/budget-request/components/budget-sum-calc/budget-sum-calc-prog-gu-table.vue';
import CBudgetFormsList from '@/modules/budget-request/components/budget-forms-list.vue';
import { Ax } from '@/utils';
import { setNameLang, makeToast } from '@/modules/budget-request/components/js/some-functions';

@Component({
    components: {
        'c-calc-prog-tab': CBudgetSumCalcProgTable,
        'c-calc-prog-gu-tab': CBudgetSumCalcProgGuTable,
        'c-budg-form-lst': CBudgetFormsList
    }
})
export default class CBudgetSumCalcProg extends Vue {
    private error: any;
    private budgetLevel: number[] = [];
    private urlFilter: any = null;
    // ----
    private curYear = (new Date()).getFullYear();
    private periodLst: any[] = [];
    private curPeriod: any = null;

    // доступность согласования
    private agrEnabled = false;
    // ---- доступность загрузки с Фаворита
    private favoriteEnabled = false;
    private impFavorit = false;
    private startLoad = false;
    private visible1 = true;
    private visible2 = false;

    private abpEnabled = false;
    private guEnabled = false;
    //--------

    private downloadLinksVisibility(visibleAttr: string){
        if (visibleAttr === 'visible1'){
            this.visible1 = true;
            this.visible2 = false;
        } else {
            this.visible1 = false;
            this.visible2 = true;
        }
    }

    private getPeriodLst() {
        const year = (new Date()).getFullYear();
        this.periodLst = [];
        for (let i = year - 2020 + 1; i > 0; i--) {
            this.periodLst.push({ name: `${2020 + i} - ${2020 + i + 2}`, year: 2020 + i });
        }
        this.curPeriod = { name: `${year + 1} - ${year + 3}`, year: year + 1 };
        this.curYear = this.curPeriod.year;
    }
    // -----------
    private obl: string | null = null;
    // private region = '450101';

    private regionBase: any[] = [];
    private curRegion: any | null = null;

    private get getRegion() {
        const result: any[] = [];
        for (const el of this.regionBase) {
            result.push(setNameLang(this.$i18n.locale, el, 'code'));
        }
        if (this.curRegion !== null && !this.curRegion.name) { this.curRegion = setNameLang(this.$i18n.locale, this.curRegion, 'code'); }
        return result;
    }

    // ----

    private tabIndx = 0;

    private curFormSelect: any = '/budget-sum-calc-prog';

    private get usrId(): string | null {
        if (!store.state.user.sub) { return null; }
        return store.state.user.sub;
    }

    // ------------вид данных-----------
    private dataTypeDictBase: any[] = [];
    private curDataType: any | null = null;

    private get dataTypeDict(): any[] {
        const result: any[] = [];
        for (const el of this.dataTypeDictBase) {
            result.push(setNameLang(this.$i18n.locale, el, 'code'));
        }
        if (this.curDataType !== null && !this.curDataType.name) { this.curDataType = setNameLang(this.$i18n.locale, this.curDataType, 'code'); }
        return result;
    }

    // --------- гос учреждение ------------
    private guBase: any[] = [];
    private curGu: any | null = null;

    private get gu(): any[] {
        const result: any[] = [];
        for (const el of this.guBase) {
            if (el.code) {
                result.push(setNameLang(this.$i18n.locale, el, 'code'));
            } else {
                result.push(el);
            }
        }
        result.sort((a, b) => (a.code > b.code) ? 1 : -1);
        return result;
    }

    // ------------------ администратор программ ------------------
    private abpBase: any[] = [];
    private curAbp: any | null = null;

    private get abp(): any[] {
        const res: any[] = [];
        for (const el of this.abpBase) {
            res.push(setNameLang(this.$i18n.locale, el, 'abp'));
        }

        if (this.curAbp !== null && !this.curAbp.name) { this.curAbp = setNameLang(this.$i18n.locale, this.curAbp, 'abp'); }
        return res;
    }

    // ------------- версия бюджета
    private curVariant: null | any = null;
    private variantBase: any[] = [];

    private get variantLst(): any[] {
        const res: any[] = [];
        for (const el of this.variantBase) {
            const tmpObj = setNameLang(this.$i18n.locale, el);
            if (el.attribute) { tmpObj.name+=' (акт)'; }
            if (el.status) { tmpObj.name += ' (утв)'; }
            res.push(tmpObj);
        }
        // if (this.curVariant !== null) { this.curVariant = this.setNameLang(this.curVariant); }
        return res;
    }

    private chgEvent(source: string) {
        const params = { curYear: this.curYear, curAbp: this.curAbp, curDataType: this.curDataType, regionProp: this.curRegion, curVariant: this.curVariant, curGu: this.curGu };
        if (this.$refs.refAbp) {
            // eslint-disable-next-line
            // @ts-ignore
            this.$refs.refAbp.chgEvent(params);
        } else {
            // eslint-disable-next-line
            // @ts-ignore
            this.$refs.refGu.chgEvent(params);
        }
    }

    private startImportFavorit() {
        this.$bvModal.msgBoxConfirm('Загрузить данные из ИС Фаворит?',
            {
            title: 'Подтверждение',
            size: 'lg',
            buttonSize: 'sm',
            okVariant: 'success',
            okTitle: 'Да',
            cancelTitle: 'Нет',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true
            })
            .then(value => {
            if (value) { this.importFavorit(false); }
        })
    }

    private async importFavorit(favoritDataTypeAgree?: boolean) {
        if (this.curAbp.abp !== 261) { return; }
        const params = { abp: this.curAbp.abp, curYear: this.curYear, region: this.curRegion.code, variant: this.curVariant.variant_uuid, data_type: this.curDataType.code, favorit_data_type_agree: favoritDataTypeAgree }
        this.startLoad = true;
        try {
            const result = await fetch(`/api-py/load-favorit-to-total/${encodeURI(JSON.stringify(params))}`);
            if (result.status === 200) {
                const json = await result.json();
                if (json.status === 'success') {
                    makeToast(this, 'success', 'Фаворит', `Загружено ${json.countRow} записей на сумму ${Math.round(json.sumRow).toLocaleString("ru-RU")}`);
                } else if (json.status === 'error') {
                    if (json.error == 'data_type') {
                        this.$bvModal.msgBoxConfirm(`Вид загружаемых данных (${json.favorit_data_type} - ${json.favorit_data_name}) не совпадает с видом данных актуальной версии бюджета. Продолжить импорт?`,
                        {
                        title: 'Подтверждение',
                        size: 'lg',
                        buttonSize: 'sm',
                        okVariant: 'success',
                        okTitle: 'Да',
                        cancelTitle: 'Нет',
                        footerClass: 'p-2',
                        hideHeaderClose: false,
                        centered: true
                        })
                        .then(value => {
                        if (value) { this.importFavorit(true); }
                        })
                    } else {
                        makeToast(this, 'danger', 'load-favorit-to-total', `${result.status}`);
                    }
                    return;
                }
            } else {
                makeToast(this, 'danger', 'load-favorit-to-total', `${result.status} - ${result.statusText}`);
            }
        } catch (error) {
            makeToast(this, 'danger', 'Ошибка запроса load-favorit-to-total', (error as Error).toString());
        } finally {
            this.startLoad = false;
        }
        this.chgEvent('importFavorit');
    }

    // ---- операцию свода абп по загрузке фаворита
    private async getImpFavorit() {
        this.impFavorit = false;
        if (!this.usrId) { return; }
        let result = null;
        const moduleCodeAbp = '004.002.002.001'; // код свод абп
        try {
            result = await fetch(encodeURI(`/api/um/module/link?user=${this.usrId}&modulecode=${moduleCodeAbp}`));
            if (result.status === 200) {
                result = await result.json();
            } else {
                if (result.status !== 404) { makeToast(this, 'danger', 'Ошибка get-realm-user', `${result.status}  ${result.statusText}`); }
                return;
            }
            if (result && result.operations) {
                for (const el of result.operations) {
                    if (el === 'imp_favorit') {
                        this.impFavorit = true;
                        return;
                    }
                }
            }
        } catch (error) {
            makeToast(this, 'danger', 'Ошибка get-realm-user ' + this.usrId, (error as Error).toString());
            return;
        }
    }

    private async loadOperations() {
        this.guEnabled = false;
        this.abpEnabled = false;
        if (this.usrId === null) { return; }
        let result = null;
        try {
            result = await fetch(encodeURI(`/api/um/module/links/${this.usrId}`));
            if (result.status === 200) {
                result = await result.json();
            }  else {
                if (result.status !== 404) { makeToast(this, 'danger', 'Ошибка загрузки списка операций пользователя', `${result.status}  ${result.statusText}`); }
                return;
            }
        } catch (error) {
            makeToast(this, 'danger', 'Ошибка загрузки списка операций пользователя', (error as Error).toString());
            return;
        }
        for (const oper of result) {
            if (oper.modules === '004.002.002.002') {
                this.guEnabled = true;
            } else if (oper.modules === '004.002.002.001') {
                this.abpEnabled = true;
            }
        }

    }


    private mounted() {
        this.loadDataTypeDict();
        this.getImpFavorit();
        this.getPeriodLst();
        this.getObl();
        this.loadVariants();
        this.loadOperations();
        // this.getObl();
        // eslint-disable-next-line
        const that = this;

        this.$watch('usrId', () => {
            if (!this.usrId) { return; }
            this.loadOperations();
            this.getImpFavorit();
            this.getObl();
            this.loadVariants();
        })

        this.$watch('curPeriod', async () => {
            if (this.curPeriod) {
                this.curYear = this.curPeriod.year;
                await this.loadActiveVariant();
                this.loadVariants();
            }
        });
        /* this.$watch('curDataType', () => {
            this.loadVariants();
        });*/
        this.$watch('curRegion', async () => {
            this.getBudgetLevel();
            await this.loadActiveVariant();
            this.loadVariants();

        });
        this.$watch('curAbp', () => {
            this.chgEvent('watch curAbp');
            this.loadGu();
            this.getFavoriteEnabled();
        });
        this.$watch('curVariant', () => {
            this.agrEnabled = false;
            this.getFavoriteEnabled();
            if (this.curVariant && this.curVariant.attribute && !this.curVariant.status) {
                this.agrEnabled = true;
            }
            this.chgEvent('watch curVariant');
        });

        this.$watch('curGu', () => {
            if (this.tabIndx === 1 || (this.tabIndx === 0 && !this.abpEnabled)) { this.chgEvent('watch curGu'); }
        });
    }


    private chgDataType() {
        this.loadVariants();
    }

    private getFavoriteEnabled() {
        this.favoriteEnabled = false;
        if (this.curVariant && this.curVariant.attribute && this.impFavorit && this.curAbp && this.curAbp.abp === 261) { this.favoriteEnabled = true; }
    }

    // ----- закгрузка активного варианта
    private async loadActiveVariant() {
        if (!this.curYear || !this.curRegion) { return; }
        const params = { year: this.curYear, region: this.curRegion.code };
        const url = `/api-py/get--active-variants-for-calcsum/${encodeURI(JSON.stringify(params))}`;
        let response: any = [];
        try {
            response = await fetch(url);
            if (response.status === 200) {
                response = await response.json();
                if (response.length) {
                    for (let i = 0; i < this.dataTypeDict.length; i++) {
                        if (response[0].data_type.toString() === this.dataTypeDict[i].code) {
                            this.curDataType = this.dataTypeDict[i];
                            break;
                        }
                    }
                }
            } else {
                makeToast(this, 'danger', 'Ошибка загрузки активных вариантов', `${response.status} - ${response.statusText}`);
            }
        } catch (error) {
            response = [];
            makeToast(this, 'danger', 'Ошибка загрузки активных вариантов', (error as Error).toString());
        }
    }

    // -------------------- load
    private async loadVariants() {
        this.variantBase = [];
        if (!this.curYear || !this.curDataType || !this.curRegion || !this.usrId) { return; }
        // await this.loadActiveVariant();
        const params = { year: this.curYear, dataType: this.curDataType.code, region: this.curRegion.code };
        const url = `/api-py/get-variants-for-calcsum/${encodeURI(JSON.stringify(params))}`;
        let response: any = [];
        try {
            response = await fetch(url);
            if (response.status === 200) {
                response = await response.json();
                this.variantBase = response;
                if (this.variantBase.length) {
                    const tmpObj = this.getFirstActiveVariant();
                    if (tmpObj.attribute) { tmpObj.name += ' (акт)'; }
                    if (tmpObj.status) { tmpObj.name += ' (утв)'; }
                    if (!this.curVariant || this.curVariant.variant_uuid!== tmpObj.variant_uuid) {
                        const flNull = (this.curVariant === null);
                        this.curVariant = tmpObj;
                        if (flNull) { this.chgEvent('loadVariants'); }
                    }
                }
            } else {
                makeToast(this, 'danger', 'Ошибка загрузки вариантов', `${response.status} - ${response.statusText}`);
                this.curVariant = null;
                this.chgEvent('loadVariants');
            }
        } catch (error) {
            this.curVariant = null;
            this.chgEvent('loadVariants');
            response = [];
            makeToast(this, 'danger', 'Ошибка загрузки вариантов', (error as Error).toString());
        }
    }

    private getFirstActiveVariant() { // получить первый активный вариант
        for (let i = 0; i < this.variantBase.length; i++) {
            const el = this.variantBase[i];
            if (el.attribute) {
                return setNameLang(this.$i18n.locale, el);
            }
        }
        if (this.variantBase.length) { return setNameLang(this.$i18n.locale, this.variantBase[0]); }
        return null;
    }

    /* private async loadBudgetLevel() {
        if (this.budgetLevel.length > 0) { return; }
        this.budgetLevel = [];

        if (!this.usrId) { return; }
        let result = [];
        try {
            result = await fetch(encodeURI('/api-py/get-user-budget-level/' + this.usrId)).then(response => response.json());
        } catch (error) {
            makeToast(this, 'danger', 'Ошибка загрузки уровня бюджета', (error as Error).toString());
            return;
        }
        if (!Array.isArray(result)) { return; }
        for (const el of result) {
            // eslint-disable-next-line @typescript-eslint/camelcase
            const indx = el.budget_level.lastIndexOf('_');
            if (indx >= 0) {
                this.budgetLevel.push(parseInt(el.budget_level.substr(indx + 1)));
            }
        }
        if (this.budgetLevel.length === 0) { makeToast(this, 'warning', 'Внимание!', 'Нет доступных уровней бюджета у пользователя!'); }

        this.loadAbp();
    } */

    private getBudgetLevel() {
        this.budgetLevel = [];
        if (this.curRegion) {
            if (this.curRegion.code.slice(this.curRegion.code.length - 4) === '0101') {
                this.budgetLevel.push(2);
            } else if (this.curRegion.code.slice(this.curRegion.code.length - 2) === '01')  {
                this.budgetLevel.push(3);
            } else if (this.curRegion.code.slice(this.curRegion.code.length - 2) !== '00') {
                this.budgetLevel.push(4);
            }
        }
        this.abpBase = [];
        this.loadAbp();
    }

    private async loadAbp() {
        if (this.abpBase.length > 0) { return; }
        if (!this.budgetLevel || this.budgetLevel.length < 1) {
            this.curAbp = null;
            return;
        }
        const url = `/api-py/get-dict-ved-abp-by-budgetlevel-user/${encodeURI(JSON.stringify(this.budgetLevel))}/${this.usrId}`;
        let response: any = [];
        try {
            response = await fetch(url);
            if (response.status === 200) {
                response = await response.json();
            } else {
                makeToast(this, 'danger', 'Ошибка загрузки адм. программ', `${response.status} - ${response.statusText}`);
            }
        } catch (error) {
            response = [];
            makeToast(this, 'danger', 'Ошибка загрузки адм. программ', (error as Error).toString());
        }

        response.sort((a: any, b: any) => (a.abp - b.abp > 0) ? 1 : -1);
        this.abpBase = this.setIdArr(response, 'abp');

        if (this.abpBase.length > 0) {
            if (this.urlFilter && this.urlFilter.abp) {
                this.curAbp = this.findElByCode('abp', this.urlFilter.abp, this.abpBase);
                this.urlFilter.abp = null;
            } else {
                this.curAbp = this.abpBase[0];
            }
        } else {
            this.curAbp = null;
        }
    }

    private getUrl() {
        // eslint-disable-next-line require-unicode-regexp
        if (window.location.href.match(/.*\?.*/)) {
            // eslint-disable-next-line require-unicode-regexp
            const tmp = decodeURIComponent(window.location.href.replace(/.*\?/, '')).split('&');
            for (const p of tmp) {
                const tmp2 = p.split('=');
                if (tmp2[0] === 'filter') {
                    this.urlFilter = JSON.parse(tmp2[1]);
                }
            }
        }
        if (this.urlFilter && this.urlFilter.year) { this.curYear = this.urlFilter.year; }
    }

    private async getObl() {
        try {
            const result = await fetch('/api-py/get-budget-obl/' + store.state._instanceCode);
            if (result.status === 200) {
                const json = await result.json();
                this.obl = json.obl;
            } else {
                makeToast(this, 'danger', 'get-budget-obl', `${result.status} - ${result.statusText}`);
            }
        } catch (error) {
            makeToast(this, 'danger', 'Ошибка запроса get-budget-obl', (error as Error).toString());
        }

        if (!this.usrId) { return; }

        try {
            await fetch('/api-py/get-user-regions-by-obl/' + this.obl + '/' + this.usrId)
                .then(response => response.json())
                .then(json => {
                    if (json.length && json[0].code) {
                        json.sort((a: any, b: any) => (a.code - b.code > 0) ? 1 : -1);
                        this.regionBase = json;
                    }

                });
        } catch (error) {
            this.regionBase = [];
            makeToast(this, 'danger', 'Ошибка запроса get-user-budget-region', (error as Error).toString());
        }
        if (this.regionBase.length) { this.curRegion = setNameLang(this.$i18n.locale, this.regionBase[0], 'code') }
        // if (this.region === region) { return; }
        // this.region = region;
        // this.loadVariants();
    }

    private async loadDataTypeDict() {
        let result: any[] = [];
        try {
            result = await fetch('/api-py/dict_budget_data_types/')
                .then(response => response.json());
        } catch (error) {
            result = [];
            makeToast(this, 'danger', 'Ошибка загрузки вида данных', (error as Error).toString());
        }
        result.sort((a, b) => (a.code > b.code) ? 1 : -1);
        this.dataTypeDictBase = result;
        if (this.dataTypeDictBase.length > 0) {
            let el = null;
            if (this.urlFilter && this.urlFilter.dataType) {
                el = this.findElByCode('code', this.urlFilter.dataType, this.dataTypeDictBase);
                this.urlFilter.dataType = null;
            } else {
                el = this.dataTypeDictBase[0];
            }
            this.curDataType = setNameLang(this.$i18n.locale, el);
        }
    }

    private setIdArr(arr: any[], codeName: string) {
        const result: any[] = [];
        for (const el of arr) {
            if (result.length > 0 && el[codeName] === result[result.length - 1][codeName]) {
                result[result.length - 1].idArr.push(el.id);
            } else {
                result.push(Object.assign({ idArr: [el.id] }, el));
            }
        }
        return result;
    }

    private findElByCode(codeName: string, code: any, arr: any[]): any {
        for (const el of arr) {
            if (el[codeName] === code) {
                return el;
            }
        }
        return null;
    }

    private openFilterByRef(refName: string) {
        const drop: any = this.$refs.drop;
        drop.show(true);
        const refItem: any = this.$refs[refName];
        setTimeout(() => refItem.$el.focus(), 100);
    }

    private loadReportOfSummaryOfCalculationAbp() {
        const params = JSON.stringify({ curYear: parseInt(this.curYear.toString()), abp: this.curAbp.abp, dataType: this.curDataType.code, region: this.curRegion.code, curVariant: this.curVariant.variant_uuid });
        const urls = '/api-py/get-budget-sum-calc-abp/'+encodeURI(params);
        Ax<Blob>(
            {
                url: urls,
                method: 'POST',
                responseType: 'blob'
            },
            (data) => {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Сводный расчет расходов АБП.xls');// or any other extension
                document.body.appendChild(link);
                link.click();
            },
            (error) => {
                this.error = error;
            }
        );
    }

    private loadReportOfSummaryOfCalculation() {
        const params = JSON.stringify({ curYear: parseInt(this.curYear.toString()), abp: this.curAbp.abp, dataType: this.curDataType.code, region: this.curRegion.code, curVariant: this.curVariant.variant_uuid, userId: this.usrId });
        const urls = '/api-py/get-budget-sum-calc-abp-gu/'+encodeURI(params);
        Ax<Blob>(
            {
                url: urls,
                method: 'POST',
                responseType: 'blob'
            },
            (data) => {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Сводный расчет расходов АБП по ГУ.xls');// or any other extension
                document.body.appendChild(link);
                link.click();
            },
            (error) => {
                this.error = error;
            }
        );
    }

    private isDownloadButtonEnabled = false;

    private loadBatchReportOfSummaryOfCalculation() {
        this.isDownloadButtonEnabled = true;
        const params = JSON.stringify({ curYear: parseInt(this.curYear.toString()), abp: this.curAbp.abp, dataType: this.curDataType.code, region: this.curRegion.code, curVariant: this.curVariant.variant_uuid });
        const urls = '/api-py/get-budget-sum-calc-abp-batch-reports/' + encodeURI(params);
        Ax<Blob>(
            {
                url: urls,
                method: 'POST',
                responseType: 'blob'
            },
            (data) => {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Пакетная выгрузка свод по АБП.xls');// or any other extension
                document.body.appendChild(link);
                link.click();
                this.isDownloadButtonEnabled = false;
            },
            (error) => {
                this.error = error;
                this.isDownloadButtonEnabled = false;
            }
        );
    }

    private agreementEvent() {
        if (this.abpEnabled && this.tabIndx === 0) {
            // eslint-disable-next-line
            // @ts-ignore
            this.$refs.refAbp.agreementEvent();
        } 
        if (this.tabIndx === 1 || (this.tabIndx === 0 && !this.abpEnabled)){
            // eslint-disable-next-line
            // @ts-ignore
            this.$refs.refGu.agreementEvent();
        }
    }

    private infoCostForm(info_id: any) {
        const that = this;
        Ax<Blob>(
            {
                url: '/api-py/get-info/' + info_id, //  Тело файла
                method: 'POST',
                data: null,
                responseType: 'blob'
            },
            (data) => {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                let file = '';
                if (info_id == 23) {
                    file = 'Инструкция Свод по АБП';
                }
                link.setAttribute('download', file + '.pdf');// or any other extension
                document.body.appendChild(link);
                link.click();
            },
            (error) => {
                that.error = error;
            }
        );
    }

    private async loadGu() {
        if (!this.curAbp || !this.usrId) {
            this.guBase = [];
            return;
        }
        let result: any[] = [];
        this.curGu = null;
        if (this.curAbp !== null) {
            try {
                result = await fetch(`/api-py/get-gu-by-abp-user-id/${this.curAbp.abp}/${this.usrId}`)
                    .then(response => response.json());
            } catch (error) {
                result = [];
                makeToast(this, 'danger', 'Ошибка загрузки гос. учреждений', (error as Error).toString());
            }
            result.unshift({ code: null, name: 'Все' });
            if (result.length > 0) {
                if (this.urlFilter && this.urlFilter.gu) {
                    this.curGu = setNameLang(this.findElByCode('code', this.urlFilter.gu, result), 'code');
                    this.urlFilter.gu = null;
                    // this.chgData();
                } else {
                    this.curGu = result[0];
                }
            }
            this.guBase = result;
        }
    }
}
