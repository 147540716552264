




































































































































































































































































































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { calcPrognWithForm, IFormBip } from '@/modules/budget-request/components/js/budgetCalcProgn';
import CBudgetAgreementModal, { iAgrBtns } from '@/modules/budget-request/components/budget-sum-calc/budget-agreement-modal.vue';
import CBudgetAgreementHistModal from '@/modules/budget-request/components/budget-sum-calc/budget-agreement-hist-modal.vue';
import store from '@/services/store';
import { makeToast } from '@/modules/budget-request/components/js/some-functions';
import { formLst } from '@/modules/budget-request/components/js/budgetFormsLst';

@Component({ 
    name: 'c-calc-prog-gu-tab',
    components: {
        'c-budg-agr-modal': CBudgetAgreementModal,
        'c-budg-agr-hist-modal': CBudgetAgreementHistModal
    }
})
export default class CBudgetSumCalcProgGuTable extends Vue {
    @Prop({
        required: true,
        default: 0
    })
    private curYearProp!: number;

    @Prop({
        required: true,
        default: null
    })
    private curAbpProp!: any | null;

    @Prop({
        required: true,
        default: null
    })
    private curDataTypeProp!: any | null;

    @Prop({
        required: true,
        default: null
    })
    private regionProp!: any | null;

    @Prop({
        required: true,
        default: null
    })
    private curVariantProp!: any | null;


    private curGu: any | null = null;
    private curYear: number = 0;
    private curAbp: any = null;
    private curDataType: any = null;
    private curRegion: any = null;
    private curVariant: any = null;

    private get region() {
        if (this.curRegion) { return this.curRegion.code; }
        return null;
    }

    private progress = 0;
    private calcData: any[] = [];
    private collapseAll = true;
    private limit: any = { sum: 0, limit: 0 };
    private totalSum: any = { 'kass_ras': 0, 'fact_ras': 0, 'utoch_plan': 0, prog1: 0, prog2: 0, prog0: 0 };

    private checkedAll = false;

    // доступность согласования 
    private agrEnabled = false;

    private readonly modeCode = 'bp';
    private operationCode = [];

    private agrBtnLst: iAgrBtns = { back: [], forward: [] };
    private selectArr: any = [];

    private mounted() {
        this.curAbp = this.curAbpProp;
        this.curYear = this.curYearProp;
        this.curDataType = this.curDataTypeProp;
        this.curRegion = this.regionProp;
        this.curVariant = this.curVariantProp;
        this.setAgrEnabled();
        this.loadData();
        this.loadOperations();
        this.$watch('curVariant', () => { 
            this.setAgrEnabled();
        });
        this.$watch('usrId', () => {
            if (!this.usrId) { return; }
            this.loadOperations();
        })
    }
    

    private async loadOperations() {
        this.operationCode = [];
        if (this.usrId === null) { return; }
        let result = null;
        try {
            result = await fetch(encodeURI(`/api/um/module/link?user=${this.usrId}&modulecode=004.002.002.002`));
            if (result.status === 200) { 
                result = await result.json();
            }  else {
                if (result.status !== 404) { makeToast(this, 'danger', 'Ошибка загрузки операций пользователя', `${result.status}  ${result.statusText}`); }
                return;
            } 
        } catch (error) {
            makeToast(this, 'danger', 'Ошибка загрузки операций пользователя', (error as Error).toString());
            return;
        }
        if (result.operations) { this.operationCode = result.operations; }
    }

    private get usrId(): string | null {
        if (!store.state.user.sub) { return null; }
        return store.state.user.sub;
    }

    private setAgrEnabled() {
        if (this.curVariant && this.curVariant.attribute && !this.curVariant.status) {
            this.agrEnabled = true;
        } else {
            this.agrEnabled = false;
        }
    }

    private chgEvent(params: any) {
        this.curAbp = params.curAbp;
        this.curYear = params.curYear;
        this.curDataType = params.curDataType;
        this.curRegion = params.regionProp;
        this.curVariant = params.curVariant;
        this.curGu = params.curGu;
        this.loadData();
    }

    private zeroDataDelete(result: any[], dataName: string) {
        for (let i = 0; i < result.length; i++) {
            const el = result[i];
            if (dataName === 'expens-prog') {
                if (!el.fact_ras && !el.kass_ras && !el.utoch_plan) {
                    result.splice(i, 1);
                    i--;
                }
            } else if (dataName === 'total-prog') {
                if (!el.limit0 && !el.limit1 && !el.limit2 && !el.value) {
                    result.splice(i, 1);
                    i--;
                }
            }
        }
    }

    // загрузка данных
    private async loadData() {
        this.checkedAll = false;
        this.calcData = [];
        this.totalSum = { 'kass_ras': 0, 'fact_ras': 0, 'utoch_plan': 0, prog1: 0, prog2: 0, prog0: 0 };
        if (!this.curAbp || !this.curDataType || !this.curYear || !this.curVariant) { return; }
        this.collapseAll = true;
        this.calcData = [];
        this.progress = 35;
        // this.totalSum = { 'kass_ras': 0, 'fact_ras': 0, 'utoch_plan': 0, prog1: 0, prog2: 0, prog0: 0 };

        // this.calcData = [];
        let result: any = null;
        const params = JSON.stringify({ usrId: this.usrId, curYear: parseInt(this.curYear.toString()), abp: this.curAbp.abp, dataType: this.curDataType.code, region: this.region, variant: this.curVariant.variant_uuid, curGu: (this.curGu? this.curGu.code: null) });
        try {
            const response: any = await fetch(`/api-py/get-budget-consolidate-expens-prog-gu/${encodeURI(params)}`);
            if (response.status === 200) {
                result = await response.json();
                if (result.status === 'success') {
                    result = result.data;
                } else if (result.status === 'error') {
                    makeToast(this, 'danger', 'Ошибка get-budget-consolidate-expens-prog', result.statusText);
                    this.progress = 0;
                    return;
                }
            } else {
                makeToast(this, 'danger', 'Ошибка get-budget-consolidate-expens-prog', `${response.status} ${response.statusText}`);
                this.progress = 0;
                return;
            }
        } catch (error) {
            makeToast(this, 'danger', 'Ошибка get-budget-consolidate-expens-prog', (error as Error).toString());
            this.progress = 0;
            return;
        }
        this.zeroDataDelete(result, 'expens-prog');
        const mapConsol = this.createTreeMap(result, true);
        this.progress = 65;
        // ------------------------------------
        result = null;
        try {
            const response: any = await fetch(`/api-py/get-req-total-prog-gu/${encodeURI(params)}`);
            if (response.status === 200) {
                result = await response.json();
                if (result.status === 'success') {
                    result = result.data;
                } else if (result.status === 'error') {
                    makeToast(this, 'danger', 'Ошибка get-req-total', result.statusText);
                    this.progress = 0;
                    return;
                }
            } else {
                makeToast(this, 'danger', 'Ошибка get-req-total', `${response.status} ${response.statusText}`);
                this.progress = 0;
                return;
             }
        } catch (error) {
            makeToast(this, 'danger', 'Ошибка get-req-total', (error as Error).toString());
            this.progress = 0;
            return;
        }
        this.progress = 85;
        this.zeroDataDelete(result, 'total-prog');
        const newMap = this.createTreeMap(result, false);

        const agreementMap: any = await this.loadTotalAgreement();
        this.getCalcData(newMap, agreementMap, mapConsol);
    }

    /**
     * сформировать calcData
     * @param newMap 
     * @param agreementMap 
     * @param mapConsol 
     */
    private getCalcData(newMap: Map<any, any>, agreementMap: Map<any, any>, mapConsol: Map<any, any>) {
        const tmpTotal = [{ totalSum: { 'kass_ras': 0, 'fact_ras': 0, 'utoch_plan': 0, prog1: 0, prog2: 0, prog0: 0 } }];
        const calcData: any[] = [];
        for (const [keyGu, valGu] of newMap) {
            // eslint-disable-next-line @typescript-eslint/camelcase
            calcData.push({ gu: keyGu, name: null, vals: [], collapse: true, totalSum: { kass_ras: 0, fact_ras: 0, utoch_plan: 0, prog1: 0, prog2: 0, prog0: 0 } });
            const progArr: any[] = [];
            let flGuAgr = false; // true - есть записи с согласованием на уровне программы
            for (const [keyProg, valProg] of valGu) {
                const pprNullArr: any[] = [];
                // eslint-disable-next-line @typescript-eslint/camelcase
                progArr.push({ name: null, prg: keyProg, vals: [], collapse: true, totalSum: { kass_ras: 0, fact_ras: 0, utoch_plan: 0, prog1: 0, prog2: 0, prog0: 0 } });
                const subArr: any[] = [];
                let flProgAgr = false; // true - есть записи с согласованием на уровне подпрограммы
                for (const [keySub, valSub] of valProg) {
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    const tmp1: any = { name: null, ppr: keySub, vals: [], collapse: true, totalSum: { kass_ras: 0, fact_ras: 0, utoch_plan: 0, prog1: 0, prog2: 0, prog0: 0, agrObj: null } };
                    if (agreementMap.get(keyGu) && agreementMap.get(keyGu).get(keyProg) && agreementMap.get(keyGu).get(keyProg).get(keySub)) {
                        const tmpAgr = agreementMap.get(keyGu).get(keyProg).get(keySub);
                        const tmpAgr2 = { id: tmpAgr.id, status: tmpAgr.status.code, updateDate: tmpAgr.update_date, userId: tmpAgr.user_id, statusName: tmpAgr.status.name_ru, status_lang: {name_ru: tmpAgr.status.name_ru, name_kk: tmpAgr.status.name_kk, name_en: tmpAgr.status.name_en, availableStep: tmpAgr.availableStep}, commentTxt: tmpAgr.commentTxt };
                        if (tmpAgr.availableStep) { flProgAgr = true; }
                        if (keySub !== null) {
                            tmp1.agrObj = tmpAgr2;
                            if (tmpAgr.availableStep) { tmp1.checked = this.checkedAll; }
                        } else {
                            progArr[progArr.length -1].agrObj = tmpAgr2;
                            if (tmpAgr.availableStep) { progArr[progArr.length -1].checked = this.checkedAll; }
                        }
                    }
                    if (keySub!==null) { subArr.push(tmp1);}
                    
                    for (const [key, value] of valSub) {
                        const obj: any = {};
                        const tmp = this.getSpfObj(value);
                        if (mapConsol.get(keyGu) && mapConsol.get(keyGu).get(keyProg) && mapConsol.get(keyGu).get(keyProg).get(keySub) && mapConsol.get(keyGu).get(keyProg).get(keySub).get(key)) {
                            const tmpCons = mapConsol.get(keyGu).get(keyProg).get(keySub).get(key);
                            obj.consExp = { 'cur_year': this.curYear, 'data_type': this.curDataType, gr: tmpCons.gr, abp: this.curAbp, prg: keyProg, ppr: keySub, spf: key, 'kass_ras': tmpCons.kass_ras, 'fact_ras': tmpCons.fact_ras, 'utoch_plan': tmpCons.utoch_plan, prog: tmpCons.prog, subProg: tmpCons.subProg, spfName: tmpCons.spfName, gu: tmpCons.gu, guName: tmpCons.guName, valueSourceLink: tmp.valueSourceLink };
                            mapConsol.get(keyGu).get(keyProg).get(keySub).delete(key);
                        } else {
                            obj.consExp = { 'cur_year': this.curYear, 'data_type': this.curDataType, gr: tmp.gr, abp: this.curAbp, prg: keyProg, ppr: keySub, spf: key, 'kass_ras': 0, 'fact_ras': 0, 'utoch_plan': 0, prog: tmp.prog, subProg: tmp.subProg, spfName: tmp.spfName, gu: tmp.gu, guName: tmp.guName, valueSourceLink: tmp.valueSourceLink };
                        }
                        for (const colName of Object.keys(value)) {
                            if (colName !== 'trash') {
                                let formBipMap = obj.formBipMap;
                                if (!formBipMap) { formBipMap = new Map() }
                                const resCalc = calcPrognWithForm(value[colName], key, formBipMap, colName);
                                obj[colName] = resCalc.val;
                                obj.formBipMap = resCalc.formBipMap;
                            }
                        }
                        this.getFormBipLst(obj);
                        if (obj.consExp.ppr !== null) {
                            subArr[subArr.length - 1].vals.push(obj);
                            this.calcSum(subArr, obj);
                            if (subArr[subArr.length - 1].name === null) { subArr[subArr.length - 1].name = obj.consExp.subProg; }
                        } else {
                            pprNullArr.push(obj);
                            this.calcSum(progArr, obj);
                        }
                             
                        if (progArr[progArr.length - 1].name === null) { progArr[progArr.length - 1].name = obj.consExp.prog; }
                        if (!calcData[calcData.length - 1].name) { calcData[calcData.length - 1].name = obj.consExp.guName; }
                    }
                    if (subArr.length>0 && keySub !== null) { this.calcSum(progArr, subArr[subArr.length - 1].totalSum); }
                }
                if (flProgAgr) { 
                    progArr[progArr.length - 1].checked = this.checkedAll; 
                    flGuAgr = true;
                }
                progArr[progArr.length - 1].vals = subArr;
                if (pprNullArr.length) { progArr[progArr.length - 1].pprNullArr = pprNullArr; }
                this.calcSum(tmpTotal, progArr[progArr.length - 1].totalSum);
                this.calcSum(calcData, progArr[progArr.length - 1].totalSum);
            }
            calcData[calcData.length - 1].vals = progArr;
            if (flGuAgr) {
                calcData[calcData.length - 1].checked = this.checkedAll;
            }
        }
        // --- добавить оставшиейся с mapConsol
        for (const [keyGu, valGu] of mapConsol) {
            for (const [keyProg, valProg] of valGu) {
                for (const [keySub, valSub] of valProg) {
                    for (const [keySpf, valSpf] of valSub) {
                        const obj = { prog0: null, prog1: null, prog2: null, consExp: valSpf, formBip: null, formBipCollapse: null }
                        // -----
                        let flGu = false;
                        for (let i = 0; i < calcData.length; i++) {
                            const calcGu= calcData[i];
                            if (calcGu.gu === keyGu) {
                                flGu = true;
                                let flProg = false;
                                for (let p = 0; p < calcGu.vals.length; p++) {
                                    const calcProg = calcGu.vals[p];
                                    if (calcProg.prg === keyProg) {
                                        flProg = true;
                                        let flSub = false;
                                        for (let s = 0; s < calcProg.vals.length; s++) {
                                            const calcSub = calcProg.vals[s];
                                            if (calcSub.ppr === keySub) {
                                                flSub = true;
                                                calcSub.vals.push(JSON.parse(JSON.stringify(obj)));
                                                calcSub.vals.sort((a: any, b: any) => (a.consExp.spf > b.consExp.spf) ? 1 : -1);
                                                this.twoObjectsTotalSum(calcSub, obj.consExp);
                                                this.twoObjectsTotalSum(calcProg, obj.consExp);
                                                this.twoObjectsTotalSum(calcGu, obj.consExp);
                                                this.twoObjectsTotalSum(tmpTotal[0], obj.consExp);
                                                break;
                                            }
                                        }
                                        if (!flSub) {
                                            const totalSub = { fact_ras: valSpf.fact_ras, utoch_plan: valSpf.utoch_plan, kass_ras: valSpf.kass_ras, prog0: 0, prog1: 0, prog2: 0 }
                                            const objSub = { agrObj: null, collapse: this.collapseAll, ppr: valSpf.ppr, name: valSpf.subProg, vals: [obj], totalSum: totalSub }
                                            calcProg.vals.push(JSON.parse(JSON.stringify(objSub)));
                                            this.twoObjectsTotalSum(calcProg, obj.consExp);
                                            this.twoObjectsTotalSum(calcGu, obj.consExp);
                                            this.twoObjectsTotalSum(tmpTotal[0], obj.consExp);
                                        }
                                        break;
                                    }
                                    calcProg.vals.sort((a: any, b: any) => (a.ppr > b.ppr) ? 1 : -1);
                                }
                                if (!flProg) {
                                    const total = { fact_ras: valSpf.fact_ras, utoch_plan: valSpf.utoch_plan, kass_ras: valSpf.kass_ras, prog0: 0, prog1: 0, prog2: 0 }
                                    if (valSpf.ppr === null) {          
                                        const pprNullObj = { prog0: 0, prog1: 0, prog2: 0, formBipCollapse: null, formBip: [], consExp: valSpf }
                                        const objProg = { collapse: this.collapseAll, prg: valSpf.prg, name: valSpf.prog, totalSum: total, vals: [], pprNullArr: [pprNullObj] };
                                        calcGu.vals.push(JSON.parse(JSON.stringify(objProg)));
                                        this.twoObjectsTotalSum(calcGu, obj.consExp);
                                        this.twoObjectsTotalSum(tmpTotal[0], obj.consExp);
                                    } else {
                                        const objSub = { agrObj: null, collapse: this.collapseAll, ppr: valSpf.ppr, name: valSpf.subProg, vals: [obj], totalSum: total }
                                        const objProg = { collapse: this.collapseAll, prg: valSpf.prg, name: valSpf.prog, totalSum: total, vals: [objSub] };
                                        calcGu.vals.push(JSON.parse(JSON.stringify(objProg)));
                                        this.twoObjectsTotalSum(calcGu, obj.consExp);
                                        this.twoObjectsTotalSum(tmpTotal[0], obj.consExp);
                                    }
                                }
                                calcData[i].vals.sort((a: any, b: any) => (a.prg > b.prg) ? 1 : -1);
                                break;
                            }
                        }
                        if (!flGu) {
                            const total = { fact_ras: valSpf.fact_ras, utoch_plan: valSpf.utoch_plan, kass_ras: valSpf.kass_ras, prog0: 0, prog1: 0, prog2: 0 }
                            let objProg = null;
                            if (valSpf.ppr === null) {
                                const pprNullObj = { prog0: 0, prog1: 0, prog2: 0, formBipCollapse: null, formBip: [], consExp: valSpf }
                                objProg = { collapse: this.collapseAll, prg: valSpf.prg, name: valSpf.prog, totalSum: total, vals: [], pprNullArr: [pprNullObj] };
                            } else {
                                const objSub = { agrObj: null, collapse: this.collapseAll, ppr: valSpf.ppr, name: valSpf.subProg, vals: [obj], totalSum: total }
                                objProg = { collapse: this.collapseAll, prg: valSpf.prg, name: valSpf.prog, totalSum: total, vals: [objSub] };
                            }
                            const guObj = { checked: null, collapse: this.collapseAll, gu: valSpf.gu, name: valSpf.guName, totalSum: total, vals: [ objProg ] }
                            calcData.push(JSON.parse(JSON.stringify(guObj)));
                            this.twoObjectsTotalSum(tmpTotal[0], obj.consExp);
                        }
                        // ----
                    }
                }
            }
        }
        calcData.sort((a: any, b: any) => (a.gu > b.gu) ? 1 : -1);
        this.calcData = calcData;
        this.totalSum = tmpTotal[0].totalSum;
        this.progress = 100;
        this.loadLimit();
    } 

    private twoObjectsTotalSum(res: any, obj: any) {
        res.totalSum.fact_ras += obj.fact_ras;
        res.totalSum.utoch_plan += obj.utoch_plan;
        res.totalSum.kass_ras += obj.kass_ras;
    }

    private getFormBipLst(obj: any) {
        if (!obj.formBipMap) { return; }
        obj.formBip = [];
        for (const [key, value] of obj.formBipMap) {
            const formBipObj: any = {};
            for (const el of value) {
                const url = this.getUrl(obj, el);
                formBipObj[el.field] = { val: el.value, url: url };
                if (formBipObj.form === undefined || formBipObj.bipCode === undefined) { 
                    formBipObj.form = el.form;
                    formBipObj.bipCode = el.bipCode;
                    formBipObj.name = el.form; 
                    formBipObj.valueSourceLink = el.valueSourceLink;
                    if (formBipObj.name===null) { formBipObj.name = el.bipCode; }
                }
            }
            obj.formBip.push(formBipObj);
        }
        obj.formBipCollapse = true;
        delete obj.formBipMap;
    }

    private getUrl(obj: any, el: any): string | null {
        if (el.valueSourceLink) {
            return `#/${el.valueSourceLink}`;
        }
        if (el.form!==null) {
            if (!obj.consExp) { return null; }
            let href: string | null = null;
            for (let i=0; i<formLst.length; i++) {
                if (formLst[i].code === el.form) {
                    href = formLst[i].href;
                    break;
                }
            }
            if (href === null) { href = `/form${el.form}`; }
            const year = parseInt(el.field[el.field.length-1]) + obj.consExp.cur_year;
            const params = `#${href}/${obj.consExp.cur_year}/${year}/${obj.consExp.data_type.code}/${obj.consExp.gr}/${this.curAbp.abp}/${obj.consExp.prg}/${obj.consExp.ppr}/${obj.consExp.gu}/${this.curVariant.variant_uuid}/${obj.consExp.spf}/no/gu/true/end`;
            return params;
        } else if (el.bipCode) {
            const params = `#/bip/bip-card?${encodeURI(`bip_code=${el.bipCode}&variant=${this.curVariant.variant_uuid}`)}`;
            return params;
        }
        return null;
    }

    private async loadTotalAgreement() {
        if (!this.operationCode.length) { return new Map(); }
        const params = JSON.stringify({ curYear: parseInt(this.curYear.toString()), abp: this.curAbp.abp, dataType: this.curDataType.code, region: this.region, variant: this.curVariant.variant_uuid, mode_code: this.modeCode, operation_code: this.operationCode });
        let result = null;
        try {
            const response: any = await fetch(`/api-py/get-budget-total-agreement-gu/${encodeURI(params)}`);
            result = await response.json();
        } catch (error) {
            makeToast(this, 'danger', 'Ошибка get-budget-total-agreement', (error as Error).toString());
            this.progress = 0;
            return new Map();
        }
        return this.createTreeMap(result, true, true);
    }

    private async loadLimit() {
        let result = null;
        let sum = 0;
        for (const el of this.calcData) {
            sum += el.totalSum.prog0;
        }
        this.limit = { sum: sum.toFixed(2), limit: 0 };
        const params = { year: parseInt(this.curYear.toString()) + 1, abp: this.curAbp.abp, region: this.region };
        try {
            const response: any = await fetch(`/api-py/get-sum-calc-total-limit/${encodeURI(JSON.stringify(params))}`);
            if (response.status === 200) {
                result = await response.json();
                if (result.limit) {
                    this.limit.limit = result.limit.toFixed(2);
                }
            } else {
                makeToast(this, 'danger', 'Ошибка get-sum-calc-total-limit', `${response.status} - ${response.statusText}`);
            }
        } catch (error) {
            makeToast(this, 'danger', 'Ошибка get-sum-calc-total-limit', (error as Error).toString());
            this.progress = 0;
        }
    }
    // -----------------------
    // ---notSpf - без специфики
    private createTreeMap(arr: any[], consolidate: boolean, notSpf?: boolean) {
        const resMap = new Map();
        for (const el of arr) {
            let tmp = resMap.get(el.gu);
            if (tmp) {
                tmp.push(el);
            } else {
                tmp = [el];
            }
            resMap.set(el.gu, tmp);
        }
        for (const [keyRes, valueRes] of resMap) {
            const progMap = new Map();
            for (const el of valueRes) {
                let tmp = progMap.get(el.prg);
                if (tmp) {
                    tmp.push(el);
                } else {
                    tmp = [el];
                }
                progMap.set(el.prg, tmp);
            }
            for (const [key, value] of progMap) {
                const subProg = new Map();
                for (const el of value) {
                    let tmp = subProg.get(el.ppr);
                    if (tmp) {
                        tmp.push(el);
                    } else {
                        tmp = [el];
                    }
                    subProg.set(el.ppr, tmp);
                }
                for (const [keySub, valSub] of subProg) {
                    const spfMap = new Map();
                    if (!notSpf) {
                        if (consolidate) {
                            for (const el of valSub) {
                                spfMap.set(el.spf, el);
                            }
                        } else {
                        // --------------------
                            for (const el of valSub) {
                                const fieldName = this.getSumFieldName(el.year);
                                const findObj = spfMap.get(el.spf);
                                if (findObj === undefined) {
                                    const newObj: any = { prog0: new Map(), prog1: new Map(), prog2: new Map(), trash: new Map() };
                                    newObj[fieldName].set(el.form, [el]);
                                    // newObj[fieldName].set(el.form, el);
                                    spfMap.set(el.spf, newObj);
                                } else if (findObj[fieldName]) {
                                    let tmpFind = findObj[fieldName].get(el.form);
                                    if (!tmpFind) { tmpFind = []; }
                                    tmpFind.push(el);
                                    findObj[fieldName].set(el.form, tmpFind);
                                    spfMap.set(el.spf, findObj);
                                }
                            }
                        }
                        subProg.set(keySub, spfMap);
                    } else {
                        if (valSub.length > 0) { subProg.set(keySub, valSub[0]); }
                    }

                }
                progMap.set(key, subProg);
            }
            resMap.set(keyRes, progMap);
        }
        return resMap;
    }

    // получение имени поля прогноза (trash - не подходящий год)
    private getSumFieldName(year: number): string {
        if (year - this.curYear < 0 || year - this.curYear > 3) { return 'trash'; }
        return `prog${(year - this.curYear)}`;
    }

    private clkCollapse(calcIndx: number | null, progIndx: number | null, subIndx: number | null, collapse?: boolean) {
        if (calcIndx === null) {
            this.collapseAll = !this.collapseAll;
            for (const el of this.calcData) {
                el.collapse = this.collapseAll;
                for (const progEl of el.vals) {
                    progEl.collapse = this.collapseAll;
                    for (const subEl of progEl.vals) {
                        subEl.collapse = this.collapseAll;
                    }
                }
            }
            return;
        }
        if (progIndx === null) {
            this.calcData[calcIndx].collapse = collapse;
            for (const el of this.calcData[calcIndx].vals) {
                el.collapse = collapse;
            }
        } else {
            if (subIndx === null) {
                this.calcData[calcIndx].vals[progIndx].collapse = collapse;
                for (const el of this.calcData[calcIndx].vals[progIndx].vals) {
                    el.collapse = collapse;
                }
            } else {
                this.calcData[calcIndx].vals[progIndx].vals[subIndx].collapse = collapse;
            }
        }
    }

    private commentClk(calcIndx: number, progIndx: number, subIndx: number | null) {
        let agrObj: any = null;
        if (subIndx!==null) {
            agrObj = this.calcData[calcIndx].vals[progIndx].vals[subIndx].agrObj;
        } else {
            agrObj = this.calcData[calcIndx].vals[progIndx].agrObj;
        }

        // eslint-disable-next-line
        // @ts-ignore
        this.$refs.refHistModal.showEvent(agrObj);
    }

    private getSpfObj(spfVal: any) {
        for (const colName of Object.keys(spfVal)) {
            if (spfVal[colName].size > 0 && spfVal[colName].entries().next().value[1].length) {
                return spfVal[colName].entries().next().value[1][0];
            }
        }
        return {};
    }

    // -------------- checked -------------------------------------------
    private clkCheck(calcIndx: number | null, progIndx: number | null, subIndx: number | null) {
        if (calcIndx === null) {
            for (const el of this.calcData) {
                if (el.checked !== undefined) {
                    el.checked = this.checkedAll;
                    this.checkAllChilds(this.checkedAll, el);
                 }
            }
            return;
        }
        let checked = this.calcData[calcIndx].checked;
        if (progIndx === null) {
            this.checkAllChilds(checked, this.calcData[calcIndx]);
        } else {
            if (subIndx === null) {
                checked = this.calcData[calcIndx].vals[progIndx].checked;
                this.checkAllChilds(checked, this.calcData[calcIndx].vals[progIndx]);
                if (!checked) {
                    this.calcData[calcIndx].checked = false;
                } else {
                    let fl = true;
                    for (let i =0; i < this.calcData[calcIndx].vals.length; i++) {
                        const el = this.calcData[calcIndx].vals[i];
                        if (el.checked === false) {
                            fl = false;
                            break;
                        }
                    }
                    this.calcData[calcIndx].checked = fl;
                }
            } else {
                checked =this.calcData[calcIndx].vals[progIndx].vals[subIndx];
                if (!checked) {
                    this.calcData[calcIndx].vals[progIndx].checked = false;
                } else {
                    let fl = true;
                    for (let i =0; i < this.calcData[calcIndx].vals[progIndx].vals.length; i++) {
                        const el = this.calcData[calcIndx].vals[progIndx].vals[i];
                        if (el.checked === false) {
                            fl = false;
                            break;
                        }
                    }
                    this.calcData[calcIndx].vals[progIndx].checked = fl;
                }
                if (!this.calcData[calcIndx].vals[progIndx].checked) {
                    this.calcData[calcIndx].checked = false;
                } else {
                    let fl = true;
                    for (let i =0; i < this.calcData[calcIndx].vals.length; i++) {
                        const el = this.calcData[calcIndx].vals[i];
                        if (el.checked === false) {
                            fl = false;
                            break;
                        }
                    }
                    this.calcData[calcIndx].checked = fl;
                }
            }
        }

        for (const el of this.calcData) {
            if (el.checked === false) {
                this.checkedAll = false;
                return;
            }
        }
        this.checkedAll = true;

    }

    // checked проставить для дочерних
    private checkAllChilds(checked: boolean, obj: any) {
        if (obj.vals) {
            for (const el of obj.vals) {
                if (el.checked!==undefined) {
                    el.checked = checked;
                    this.checkAllChilds(checked, el);
                }
            }
        }

    }

    // ------------------- end checked ----------------------

    private calcSum(sumArr: any[], obj: any) {
        // eslint-disable-next-line @typescript-eslint/camelcase
        let consObj = obj;
        if (obj.consExp) { consObj = obj.consExp; }
        // eslint-disable-next-line @typescript-eslint/camelcase
        if (consObj.kass_ras) { sumArr[sumArr.length - 1].totalSum.kass_ras += consObj.kass_ras; }
        // eslint-disable-next-line @typescript-eslint/camelcase
        if (consObj.fact_ras) { sumArr[sumArr.length - 1].totalSum.fact_ras += consObj.fact_ras; }
        // eslint-disable-next-line @typescript-eslint/camelcase
        if (consObj.utoch_plan) { sumArr[sumArr.length - 1].totalSum.utoch_plan += consObj.utoch_plan; }
        if (obj.prog1) { sumArr[sumArr.length - 1].totalSum.prog1 += obj.prog1; }
        if (obj.prog2) { sumArr[sumArr.length - 1].totalSum.prog2 += obj.prog2; }
        if (obj.prog0) { sumArr[sumArr.length - 1].totalSum.prog0 += obj.prog0; }
    }

    // кнопка действия
    private async agreementEvent() {
        const selectArr: any = [];
        this.selectArr = [];
        this.agrBtnLst = { back: [], forward: [] };
        let firstStatus: number | null = null;
        for (const calc of this.calcData) {
            for (const prog of calc.vals) {
                if (prog.pprNullArr && prog.pprNullArr.length && prog.checked && prog.agrObj) { // без подпрограммы
                    if (firstStatus === null) { firstStatus = prog.agrObj.status; }
                    if (firstStatus !== prog.agrObj.status) {
                        makeToast(this, 'danger', 'Согласование', 'Выберите подпрограммы с одинаковым статусом!');
                        return;
                    }
                    selectArr.push({ consExp: prog.pprNullArr[0].consExp, agrObj: prog.agrObj });
                }
                for (const sub of prog.vals) {
                    if (sub.checked && sub.vals.length && sub.agrObj) {
                        if (firstStatus === null) { firstStatus = sub.agrObj.status; }
                        if (firstStatus !== sub.agrObj.status) {
                            makeToast(this, 'danger', 'Согласование', 'Выберите подпрограммы с одинаковым статусом!');
                            return;
                        }
                        selectArr.push({ consExp: sub.vals[0].consExp, agrObj: sub.agrObj })
                    }
                }
            }
        }
        if (selectArr.length === 0) {
            makeToast(this, 'warning', 'Согласование', 'Не выбрано ни одной программы/подпрограммы!');
            return;
        }
        this.selectArr = selectArr;
        // ---------------

        const params = { modeCode: this.modeCode, operationCode: this.operationCode, agrCode: firstStatus };
        let result = null;
        try {
            const response: any = await fetch(`/api-py/get-agreement-step-next-back/${encodeURI(JSON.stringify(params))}`);
            result = await response.json();
        } catch (error) {
            makeToast(this, 'danger', 'Ошибка get-agreement-step-next-back', (error as Error).toString());
            this.progress = 0;
            return;
        }
        if (result.length === 0) {
            makeToast(this, 'warning', 'Согласование', 'Нет доступных шагов согласования!');
            return;
        }
        for (const el of result) {
            if (el.stepType === 1) {
                this.agrBtnLst.back.push(el);
            } else {
                this.agrBtnLst.forward.push(el);
            }
        }
        // eslint-disable-next-line
        // @ts-ignore
        this.$refs.refAgrModal.showEvent();
    }

    private async agrClk(data: any) {
        data.userId = store.state.user.sub;
        const saveObj = [];
        for (const el of this.selectArr) {
            saveObj.push(Object.assign({ id: el.agrObj.id }, data));
        }

        let result: any = await fetch('/api-py/set_brft_agreement_step_gu',
            {
                method: 'POST',
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json'
                },
                redirect: 'follow',
                referrerPolicy: 'no-referrer',
                body: JSON.stringify(saveObj)
            });
        if (result.status === 200) {
            result = await result.json();
            for (const res of result) {
                if (res.result === 'error') {
                    makeToast(this, 'danger', 'Сохранение status', `Ошибка ${result.data}`);
                    return;
                }
            }
            this.loadData();
        } else {
            makeToast(this, 'danger', 'Сохранение status', `Ошибка ${result.status} ${result.statusText}`);
            return;
        }
    }
}
